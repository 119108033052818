<template>
  <div class="cashExplainWarp" :style="{minHeight:clientHeight}">
    <img :src="deviceCash" class="devicecash-img">
    <div class="content">
       <div class="title">站点设备（保险柜、点钞机、监控设备等）由村口科技提供和管理。村口科技本着“共同投入、共同受益”的合作原则收取站点设备押金。</div>
       <div class="content-wrap">
         <div class="content-item">
          <div class="mid">押金收取方式</div>
          <div class="mid-content">站长根据选择使用的设备情况，将相应的设备押金通过手机银行、柜台转账、线上付款等形式支付到公司指定账户，付款时需备注设备押金及站点名称、站长姓名。</div>
        </div>
        <div class="content-item">
          <div class="mid">设备押金返还</div>
          <div class="mid-content">站点正常退出，村口科技验收设备，如设备无故意损毁，设备押金按原路全额退还给站长；如设备有损坏，按照损坏程度折价退还设备押金。</div>
        </div>
       </div>
    </div>
    
  </div>
</template>

<script>
import { uwStatisticAction } from "../../utils/util";
export default {
  name: "cashExplain",
  data () {
    return {
      clientHeight: '667px',
      deviceCash: require('@/assets/img/device/deviceCash.png')
    }
  },
  mounted () {
    this.clientHeight = (document.body.clientHeight) + 'px'
    uwStatisticAction('/cashExplain', '设备押金说明')
  },
  methods: {

  },

}
</script>

<style scoped lang="less">
.cashExplainWarp {
  background: #F7F8F9;
  position: relative;
  height: 100%;
  min-height: 100%;
  .devicecash-img{
    width: 100%;
  }
  .content{
    position: absolute;
    left: 0;
    top: 0;
    padding: 0.3rem;
    .title{
      color: #fff;
      margin: 0.38rem 0.12rem ;
      font-size: 0.3rem;
    }
  }
  .content-wrap{
    margin-top: 2rem;
    .content-item{
      position: relative;
      width: 6.9rem;
      height: 2.94rem;
      background: #fff;
      border-radius: 0.16rem;
      box-shadow: 0px 14px 40px 0px rgba(160,190,163,0.2);
      margin-bottom: 1rem;
      .mid{
        width: 2.4rem;
        height: 0.8rem;
        line-height: 0.8rem;
        background: #CBEED6;
        color: #373538;
        font-size: 0.32rem;
        border-radius: 0.16rem;
        font-weight: bold;
        text-align: center;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -0.4rem;
      }
      .mid-content{
        padding: 0.82rem 0.46rem;
        font-size: 0.28rem;
        color: #2E2F30;
      }
    }
  }
  div {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #2e2f30;
    letter-spacing: 1px;
  }
  h5 {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #272829;
    margin: 32px 0 16px 0;
  }
}
</style>